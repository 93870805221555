import { useEffect } from "react";
import { TextField, Typography, LinearProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { BackupOutlined } from "@material-ui/icons";
import FileRow from "../file/FileRow";
import AuthService from "../services/AuthService";
import { useSnackbar } from "notistack";
import ProceedingService from "../services/ProceedingService";

export const ApplicationInnerForm = ({ newProduct = false, ...props }) => {
  console.log(props);

  const { enqueueSnackbar } = useSnackbar();

  const expirationDateIsRequired = () => {
    if (props?.proceeding_id?.expiration_date_field === false) {
      return false;
    }
    return props?.statuses?.[props?.last_iteration?.status]?.fields
      ?.expiration_date?.required;
  };

  const hanbleChangePriority = (event, newInputValue) => {
    if (AuthService.userIsAdmin() || AuthService.userIsEditor()) {
      return props._handleChangePriorities(event, newInputValue, props);
    }

    enqueueSnackbar("No tiene permisos para modificar la prioridad");
    event.preventDefault();
    return false;
  };

  const showCertificate = () => {
    if (
      ProceedingService.isReinscriptionOrRenovation(props?.proceeding_id?.name)
    ) {
      return true;
    }
    return props.statuses?.[props?.last_iteration?.status]?.fields?.field_1;
  };

  const requiredCertificate = () => {
    return props.statuses?.[props?.last_iteration?.status]?.fields?.field_1
      ?.required;
  };

  useEffect(() => {
    var elements = document.getElementsByClassName("MuiInputBase-input");
    Array.from(elements).forEach((element) => {
      element.setAttribute("autocomplete", "textRandom" + Date.now());
    });
  }, []);

  return (
    <form
      className={props.classes.root + " m-3"}
      onSubmit={props.handleSubmit}
      autoComplete="false"
    >
      <Typography variant="h5" component="h2" className={"pt-3 pb-3"}>
        Presentaciones: {props.name}
      </Typography>

      {!newProduct ? (
        <div className="row">
          <div className="col-sm-6">
            <div className={"pt-3"}>
              Producto: {props.product?.display_name}
            </div>
          </div>
          <div className="col-sm-6">
            <div className={"pt-3"}>Cliente: {props.product?.client?.name}</div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        <div className="col-sm-6">
          <Autocomplete
            id="organization_id"
            options={props.organizations}
            getOptionSelected={(option, value) => {
              return (
                option._id === value._id ||
                option.organization_name === value.organization_name
              );
            }}
            getOptionLabel={(option) => {
              return option.organization_name || "";
            }}
            value={
              props.organization_id?.organization_name
                ? props.organization_id
                : null
            }
            required={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!props.organization_id || !props.organization_id._id}
                label="Organismo"
              />
            )}
            onChange={(event, newInputValue) =>
              props._handleChangeOrg(event, newInputValue, "organization_id")
            }
          />
        </div>
        <div className="col-sm-6">
          <Autocomplete
            id="proceeding_id"
            options={props.proceedings}
            getOptionSelected={(option, value) => {
              return option._id === value._id || option.name === value.name;
            }}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
            value={props.proceeding_id?.name ? props.proceeding_id : null}
            required={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!props.proceeding_id || !props.proceeding_id._id}
                label="Trámite"
              />
            )}
            onChange={(event, newInputValue) =>
              props.handleChangeProceeding(
                event,
                newInputValue,
                "proceeding_id"
              )
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Autocomplete
            id="last_iteration.status"
            options={props.statusesList}
            getOptionSelected={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(option) => {
              return option || "";
            }}
            value={
              props?.last_iteration?.status
                ? props?.last_iteration?.status
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!props?.last_iteration?.status}
                label="Estado"
              />
            )}
            autoComplete={false}
            onChange={(event, newInputValue) =>
              props._handleChangeStatus(event, newInputValue, props)
            }
          />
        </div>
        <div className="col-sm-6">
          <Autocomplete
            id="last_iteration.substatus"
            options={
              props.statuses?.[props?.last_iteration?.status]?.substatuses || []
            }
            getOptionSelected={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(option) => {
              return option || "";
            }}
            value={
              props?.last_iteration?.substatus
                ? props?.last_iteration?.substatus
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={
                  props.statuses?.[props?.last_iteration?.status]?.fields
                    ?.substatus?.required && !props?.last_iteration?.substatus
                    ? true
                    : undefined
                }
                label="Subestado"
              />
            )}
            autoComplete={false}
            disabled={
              !props.statuses?.[props?.last_iteration?.status]?.substatuses
                ?.length
            }
            onChange={(event, newInputValue) =>
              props._handleChangeSubstatus(event, newInputValue, props)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <TextField
            id="last_iteration.field_0"
            label="Expediente"
            placeholder="Expediente"
            value={props?.last_iteration?.field_0}
            onChange={props.handleTextFieldChange}
            disabled={
              !props.statuses?.[props?.last_iteration?.status]?.fields?.field_0
            }
            error={
              props.statuses?.[props?.last_iteration?.status]?.fields?.field_0
                ?.required && !props?.last_iteration?.field_0
                ? true
                : undefined
            }
            required={
              props.statuses?.[props?.last_iteration?.status]?.fields?.field_0
                ?.required
            }
          />
        </div>
        <div className="col-sm-6">
          <TextField
            id="last_iteration.field_1"
            label="Certificado"
            placeholder="Certificado"
            value={props?.last_iteration?.field_1}
            onChange={props.handleTextFieldChange}
            // disabled={!props.statuses?.[props?.last_iteration?.status]?.fields?.field_1}
            disabled={!showCertificate()}
            error={
              requiredCertificate() && !props?.last_iteration?.field_1
                ? true
                : undefined
            }
            required={requiredCertificate()}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <TextField
            id="last_iteration.comments"
            label="Comentario"
            placeholder="Comentario"
            value={props?.last_iteration?.comments}
            onChange={props.handleTextFieldChange}
            disabled={
              !props.statuses?.[props?.last_iteration?.status]?.fields?.comments
            }
            error={
              props.statuses?.[props?.last_iteration?.status]?.fields?.comments
                ?.required && !props?.last_iteration?.comments
                ? true
                : undefined
            }
            required={
              props.statuses?.[props?.last_iteration?.status]?.fields?.comments
                ?.required
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              margin="normal"
              id="expiration_date"
              label="Vencimiento"
              cancelLabel="Cancelar"
              clearLabel="Limpiar"
              clearable={true}
              value={props?.expiration_date ?? null}
              onChange={props.handleDateChange}
              invalidDateMessage="Fecha inválida"
              disabled={
                !props.statuses?.[props?.last_iteration?.status]?.fields
                  ?.expiration_date
              }
              error={
                expirationDateIsRequired() &&
                !props?.last_iteration?.expiration_date
                  ? true
                  : false
              }
              required={expirationDateIsRequired()}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="col-sm-6">
          <Autocomplete
            id="last_iteration.asign_to"
            options={props.users}
            getOptionSelected={(option, value) => {
              return option === value;
            }}
            getOptionLabel={(option) => {
              return option || "";
            }}
            value={
              props?.last_iteration?.asign_to
                ? props?.last_iteration?.asign_to
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Asignado a"
                error={
                  props.statuses?.[props?.last_iteration?.status]?.fields
                    ?.asign_to?.required && !props?.last_iteration?.asign_to
                    ? true
                    : undefined
                }
              />
            )}
            onChange={(event, newInputValue) =>
              props._handleChangeUser(event, newInputValue, props)
            }
            disabled={
              !props.statuses?.[props?.last_iteration?.status]?.fields?.asign_to
            }
            required={
              props.statuses?.[props?.last_iteration?.status]?.fields?.asign_to
                ?.required
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {AuthService.userIsAdmin() ? (
            <Autocomplete
              id="priority"
              options={props.priorities}
              getOptionSelected={(option, value) => option.id === value}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
              value={props?.priority}
              renderInput={(params) => (
                <TextField {...params} label="Prioridad" />
              )}
              onChange={hanbleChangePriority}
              // disabled={!props.statuses?.[props?.last_iteration?.status]?.fields?.asign_to}
              // required={props.statuses?.[props?.last_iteration?.status]?.fields?.asign_to?.required}
            />
          ) : (
            <TextField
              id="priority"
              label="Priority"
              placeholder="Priority"
              value={props?.priority?.name}
              disabled={true}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <span className="font-weight-bold">Documentación</span>
        </div>
        {props.state?.requiredDocumentation && (
          <div className="col-sm-6">
            <span className="font-weight-bold">Documentación requerida</span>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-sm-6">
          {(AuthService.userIsAdmin() || AuthService.userIsEditor()) && (
            <>
              <a href="#a" onClick={() => props.clickById("inputUpload")}>
                <BackupOutlined color={props.fileError ? "error" : undefined} />
              </a>
              <input
                type="file"
                id="inputUpload"
                onChange={props.onFileChange}
                hidden={true}
              />
            </>
          )}
          <div className="bucketFilesContainer">
            {props?.last_iteration?.bucketFiles?.map((file, index) => (
              <FileRow
                file={file}
                onDelete={props.onDeleteFile}
                index={index}
                key={file?.filePath || index}
                delete={true}
              />
            ))}
          </div>
        </div>
        <div className="col-sm-6">
          {props.state?.loadingRequiredDocumentation && <LinearProgress />}
          {props.state?.requiredDocumentation}
        </div>
      </div>
    </form>
  );
};
